<template>
  <section>
    <title-section
      title="Detalle del usuario"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    />
    <user-detail-main />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserDetail',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    UserDetailMain: () => import('@/components/Users/Detail/UserDetailMain.vue')
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
